<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">
      <!-- 养殖年产值与产量趋势 -->
      <div class="content-item trend-aquaculture">
        <div class="content-title">
          <span>养殖年产值与产量趋势</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.breedLineBarChart.lineChart.length"
          element-loading-background="transparent"
        >
          <div
            id="trend-aquaculture-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 养殖品种年产量 -->
      <div class="content-item breeding-varieties">
        <div class="content-title">
          <span>养殖品种年产量</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.breedValueList.length"
          element-loading-background="transparent"
        >
          <div
            id="breeding-varieties-chart"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <!-- 养殖品种 -->
      <div class="content-item aquaculture-species">
        <div class="content-title">
          <span>养殖品种</span>
        </div>
        <div class="content-charts">
          <swiper :options="swiperOption">
            <swiper-slide
              v-for="(ele, idx) in infoData.breedVarietiesList"
              :key="idx"
            >
              <div class="chart-item">
                <div class="img-box">
                  <img :src="ele.imageUrl" alt />
                  <p>{{ ele.breedName }}</p>
                </div>
                <p>
                  售卖价格：
                  <span>{{ ele.breedPrice }}</span
                  >元
                </p>
                <p>
                  今年销量：
                  <span>{{ ele.incomeSalesThisYear }}</span
                  >万吨
                </p>
                <p>
                  今年销售收入：
                  <span>{{ ele.salesThisYear }}</span
                  >亿元
                </p>
                <p>
                  养殖塘口：
                  <span>{{ ele.breedBox }}</span
                  >个
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.annualOutputValueTotal || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">年总产值/亿元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="1"
              :start-val="0"
              :end-val="+infoData.farmingYearSalesTotal || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">养殖年销售/亿元</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="2"
              :start-val="0"
              :end-val="+infoData.deepSeaCageNumber || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">养殖塘口/个</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.aquacultureProduction || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">养殖产量/万吨</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo
              :key="20"
              :start-val="0.1"
              :end-val="+infoData.breedingSpecies || 0"
              :duration="2500"
            ></CountTo>
          </div>
          <div class="item-txt">养殖品种/个</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 主要养殖品种价格走势 -->
      <div class="content-item price-trend">
        <div class="content-title">
          <span>主要养殖品种价格走势</span>
        </div>
        <div
          class="content-charts"
          v-loading="!infoData.breedPriceLineMoreChart.list"
          element-loading-background="transparent"
        >
          <div id="price-trend-chart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <!-- 苗种培育 -->
      <div class="content-item seed-culture">
        <div class="content-title">
          <span>苗种培育</span>
        </div>
        <div class="content-charts">
          <div class="top-part">
            <div class="part-item">
              <p>
                {{
                  infoData.fryBreedingArea &&
                  infoData.fryBreedingArea.split('.')[0]
                }}
              </p>
              <p>鱼苗培育区</p>
              <p>(亩)</p>
            </div>
            <div class="part-item">
              <p>
                {{
                  infoData.nurseryProductionArea &&
                  infoData.nurseryProductionArea.split('.')[0]
                }}
              </p>
              <p>育苗生产区</p>
              <p>(亩)</p>
            </div>
            <div class="part-item">
              <p>
                {{
                  infoData.standardCoarsePond &&
                  infoData.standardCoarsePond.split('.')[0]
                }}
              </p>
              <p>标粗池塘</p>
              <p>(亩)</p>
            </div>
            <div class="part-item">
              <p>
                {{
                  infoData.stockEstimateThisYear &&
                  infoData.stockEstimateThisYear.split('.')[0]
                }}
              </p>
              <p>今年存量预估</p>
              <p>(亿吨)</p>
            </div>
          </div>
          <div class="bot-part">
            <swiper :options="swiperOption2">
              <swiper-slide
                v-for="(ele, idx) in infoData.larvalRearingList"
                :key="idx"
                @click="dialogFormVisible = true"
              >
                <div class="part-item">
                  <div class="img-box">
                    <img :src="ele.url" alt />
                  </div>
                  <p>({{ ele.name }})</p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!------------------------------ 右部分区域 end ------------------------------>
    <el-dialog
      title="中兴1号"
      width="882px"
      top="30vh"
      :visible.sync="dialogFormVisible"
    >
      <div class="module">
        <div class="module-content">
          “中兴1号”是由广东恒兴饲料实业股份有限公司与中山大学合作，以抗白斑综合症病毒（WSSV）为主要选育指标，经1代群体选育和5代家系选育获得的南美白对虾抗病品种，2011年通过国家审定（品种登记号：GS-01-008-2010）。
          该品种与夏威夷引进的南美白对虾相比，抗病评价指数高，养殖成活率提高。适宜在我国海水、咸淡水、淡水水域养殖。
          “中兴1号”是由广东恒兴饲料实业股份有限公司与中山大学合作，以抗白斑综合症病毒（WSSV）为主要选育指标，经1代群体选育和5代家系选育获得的南美白对虾抗病品种，2011年通过国家审定（品种登记号：GS-01-008-2010）。
          该品种与夏威夷引进的南美白对虾相比，抗病评价指数高，养殖提……高。适宜在我国海水、咸淡水、淡水水域养殖。
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

import Vue from 'vue'
import { Progress, Dialog } from 'element-ui'
Vue.use(Dialog).use(Progress)

// import videoDemo from '../../utils/videoDemo/EZUIKitJs.vue'
import CountFlop from '../../../components/count-to-component.vue'
import containerTab from '../../../components/container-tab.vue'

import * as $echarts from 'echarts'
import CountTo from 'vue-count-to'
export default {
  name: 'breeding-seeding',
  components: {
    // videoDemo,
    CountFlop,
    CountTo,
    containerTab,
    swiper,
    swiperSlide,
  },
  inject: ['app'],
  data() {
    return {
      infoData: {
        breedLineBarChart: {
          lineChart: [],
        }, //	养殖年产值与产量趋势
        breedPriceLineMoreChart: [], // 主要养殖品种价格走势
        breedValueList: [], // 养殖品种年产量
        breedVarietiesList: [], // 养殖品种
        larvalRearingList: [], // 苗种培育
      },
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: 16,
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 1000, //1秒切换一次
        // },
        // mousewheel: true,
        slidesPerView: 2,
        // loop: true,
      },
      swiperOption2: {
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: 15,
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 1000, //1秒切换一次
        // },
        // mousewheel: true,
        slidesPerView: 3,
        // loop: true,
      },
      dialogFormVisible: false,
      map: null,
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      // 养殖年产值与产量趋势
      trend: {
        idName: 'trend-aquaculture-chart',
        legend: {
          data: ['养殖产量', '年产值'],
        },
        series: {
          lineData: {
            name: '养殖产量',
            data: [],
          },
          barData: {
            name: '年产值',
            data: [],
          },
        },
        xAxis: {
          data: ['2017年', '2018年', '2019年', '2020年', '2021年'],
        },
        yAxis: {},
      },
      // 养殖品种年产量
      breeding: {
        idName: 'breeding-varieties-chart',
        series: {
          data: [
            {
              value: 7,
              name: '红鱼',
            },
            {
              value: 6,
              name: '金鳗鱼',
            },
            {
              value: 5,
              name: '龙趸鱼',
            },
            {
              value: 4,
              name: '三刀鱼',
            },
            {
              value: 8,
              name: '海鲡鱼',
            },
          ],
        },
      },
      // 主要养殖品种价格走势
      project: {
        idName: 'price-trend-chart',
        legend: {
          data: ['金鲳鱼', '龙趸鱼', '海鲡鱼', '三刀鱼', '红鱼'],
        },
        xAxis: {
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月'],
        },
        series: [],
      },
    }
  },
  computed: {},
  methods: {
    // 折线图
    trendAquacultureChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(16),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(36, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 4,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
          {
            type: 'value',
            splitNumber: 4,
            nameTextStyle: {
              color: '#838DA1',
            },
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.lineData.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: fontHandle(2),
              color: '#F06CFA',
            },
            data: obj.series.lineData.data,
            smooth: true,
            symbolSize: 0,
          },
          {
            name: obj.series.barData.name,
            type: 'bar',
            yAxisIndex: 1,
            data: obj.series.barData.data,
            barWidth: fontHandle(10),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    priceTrendChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let seriescolor = ['#5B49FF', '#F55AFB', '#5DF7E4', '#80EBD5', '#33CEFA']
      let series = obj.legend.data.map((ele, idx) => {
        return {
          name: ele,
          type: 'line',
          itemStyle: {
            normal: {
              color: seriescolor[idx],
            },
          },
          lineStyle: {
            width: 2.5,
            color: seriescolor[idx],
          },
          data: obj.series[idx],
          smooth: true,
          symbolSize: 0,
        }
      })
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(46, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series,
      }
      chart.setOption(option)
    },
    // 玫瑰图
    breedingVarietiesChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let xData = []
      let total = 0
      obj.series.data.forEach(function (item) {
        xData.push(item.name)
        total = item.value + total
      })
      let color1 = ['#5442FF', '#F553FB', '#73EBCB', '#11AEF1', '#2bccfa']
      let option = {
        color: color1,
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        polar: {},
        angleAxis: {
          interval: 1,
          type: 'category',
          data: [],
          z: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#0B4A6B',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            interval: 0,
            show: true,
            color: '#0B4A6B',
            margin: fontHandle(8),
            fontSize: fontHandle(16),
          },
        },
        radiusAxis: {
          show: false,
        },
        calculable: true,
        series: [
          {
            stack: 'a',
            type: 'pie',
            radius: ['10%', '90%'],
            roseType: 'area',
            zlevel: 10,
            label: {
              normal: {
                show: true,
                formatter: '\n{font|{b}}\n{font2|{d}}',
                rich: {
                  font: {
                    fontSize: fontHandle(18),
                    color: '#D1E2F4',
                  },
                  font2: {
                    fontSize: fontHandle(24),
                    padding: [5, 0],
                    color: '#2AEDFF',
                    fontWeight: 'bold',
                  },
                },
                borderRadius: fontHandle(20),
                position: 'outside',
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {},
            labelLine: {
              normal: {
                show: true,
              },
              emphasis: {
                show: false,
              },
            },
            data: obj.series.data,
          },
        ],
      }
      chart.setOption(option)
    },
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后

    this.$axios('/zhanjiang-dataview/viewBreedSeedling/queryInfo').then(
      (res) => {
        this.infoData = res.data
        this.trend.xAxis.data = res.data.breedLineBarChart.years
        this.trend.series.barData.data = res.data.breedLineBarChart.barChart
        this.trend.series.lineData.data = res.data.breedLineBarChart.lineChart
        this.breeding.series.data = res.data.breedValueList
        this.project.xAxis.data = res.data.breedPriceLineMoreChart.month
        this.project.legend.data = res.data.breedPriceLineMoreChart.list.map(
          (ele) => ele.name
        )
        this.project.series = res.data.breedPriceLineMoreChart.list.map(
          (ele) => ele.values
        )

        this.trendAquacultureChart(this.trend)
        this.breedingVarietiesChart(this.breeding)
        this.priceTrendChart(this.project)
      }
    )
  },
  created() {},
  beforeUpdate() {
    // 组件更新之前
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './breeding-seeding.less';
</style>
